.root {
	--gap: 20px;

	width: 100%;
	margin-top: 47px;
	padding-inline: 0;
	padding-top: 0;
	display: flex;
	flex-direction: column;
	gap: var(--gap);

	@media (--md) {
		--gap: 40px;

		padding-top: 90px;
	}

	& .header {
		text-align: center;
	}

	& .items {
		--gap: 5px;

		width: 100%;
		margin-inline: 4px;

		@media (--md) {
			max-width: var(--text-width);
			margin-inline: auto;
		}

		display: flex;
		justify-content: center;
		gap: var(--gap);
		flex-wrap: wrap;

		@media (--md) {
			--gap: 15px;
		}
	}
}
