.root {
	max-width: max(var(--page-inset-width), 351px);
	position: relative;
	padding: var(--space-xs);
	padding-bottom: 24px;
	border-radius: var(--border-radius-md);
	background-color: var(--primary-white);
	box-shadow: 0 4px 10px 0 rgb(0 0 0 / 10%);
	z-index: var(--z-sticky);

	@media (--md) {
		max-width: 347px;
	}

	@media (hover: hover) {
		&:hover {
			cursor: pointer;

			& .contentHeader {
				& a {
					text-decoration: underline;
				}
			}
		}
	}
}

.tag {
	position: absolute;
	top: var(--space-xs);
	left: var(--space-xs);
	z-index: var(--z-above);
}

.carouselItem {
	aspect-ratio: 4 / 5;
	position: relative;
}

/* Nested for specificity */
.carousel {
	--slide-size: 70%;
	--slide-spacing: var(--space-2xs);

	@media (--md) {
		--slide-size: 100%;
		--slide-spacing: 0;
	}

	& .control {
		top: unset;
		bottom: var(--space-xs);
		left: unset;
		right: var(--space-xs);
	}

	& .prev {
		transform: translateY(0) translateX(calc(-100% - var(--space-xs)));
	}

	& .next {
		transform: translateY(0);
	}
}

.content {
	margin-top: var(--space-md);
	padding-inline: var(--space-xs);
}

.contentHeader {
	padding-bottom: var(--space-2xs);
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: var(--space-sm);

	& a {
		@media (--max-sm) {
			display: contents;
		}

		text-decoration: none;
		font: inherit;
		line-height: inherit;

		@media (hover: hover) {
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.button {
	width: 100%;
	margin-top: var(--space-md);

	@media (--md) {
		display: none;
	}
}
