.skeleton {
	width: 100%;
	height: auto;
	aspect-ratio: 149 / 64;
}

.map {
	--map-height: auto;
	--map-marker-bg: var(--primary-white);
	--map-marker-size: 30px;

	height: var(--map-height);
	aspect-ratio: 130 / 64;
}

.trigger {
	background-color: var(--primary-white);
	stroke: var(--secondary-blue);
	border-radius: 100%;
	padding: 1px;

	&[data-open="true"] {
		background-color: var(--secondary-blue);
		stroke: var(--primary-white);
	}
}
