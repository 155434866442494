:where(.mapContainer) {
  width: 100%;
  height: 600px;
  position: relative;
}

:where(.map) {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}
