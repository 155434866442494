.root {
	--gap: 20px;

	display: flex;
	flex-direction: column;
	gap: var(--gap);

	@media (--md) {
		--gap: 60px;
	}

	& .retailers {
		--retailers-gap: 10px;

		width: 100%;
		display: grid;
		gap: var(--retailers-gap);
		grid-template-columns: 1fr;

		@media (--md) {
			--retailers-gap: 15px;

			grid-template-columns: repeat(2, 1fr);
		}

		@media (--lg) {
			--retailers-gap: 15px;

			grid-template-columns: repeat(3, 1fr);
		}

		@media (--xl) {
			--retailers-gap: 15px;

			grid-template-columns: repeat(4, 1fr);
		}
	}
}
