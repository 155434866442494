.root {
	--size: 32px;

	appearance: none;
	border: none;
	background-color: var(--primary-dark-blue-04);
	border-radius: 50%;
	width: var(--size);
	height: var(--size);
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: var(--transition-standard);
	transition-property: transform;

	&::after {
		width: calc(var(--size) / 2);
		height: calc(var(--size) / 2);
		display: block;
		content: "";
		border-radius: 50%;
		background-color: var(--primary-white);
	}

	&:active {
		transform: scale(0.95);
	}
}
