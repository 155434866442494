.mapboxgl-popup-close-button.mapboxgl-popup-close-button {
  display: none;
}

.mapboxgl-popup-content.mapboxgl-popup-content {
  padding: 0;
}

.mapboxgl-popup.mapboxgl-popup {
  max-width: 100% !important;
}
