.content {
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	transition: var(--transition-standard);
	z-index: var(--z-sticky);

	&[data-state="open"] {
		visibility: visible;
		opacity: 1;
		pointer-events: auto;
	}
}
