.root {
	& .text {
		max-width: var(--text-width);

		&.center {
			text-align: center;
			margin: 0 auto;
		}
	}

	.title {
		margin-bottom: var(--space-md);

		@media (--md) {
			margin-bottom: var(--space-lg);
		}
	}
}
