.root {
	position: relative;
	overflow: visible;

	& .productCards {
		width: 100%;
		display: none;

		@media (--lg) {
			max-width: 50%;
			position: absolute;
			bottom: 0;
			left: 0;
			padding: 60px;
			display: flex;
			gap: 10px;
		}

		& .productCard {
			width: 100%;
			padding: 10px 10px 20px;
			background: var(--primary-white);
			border-radius: 8px;
		}
	}

	& .tags {
		display: contents;

		&.hideOnLargeDisplay {
			@media (--lg) {
				display: none;
			}
		}
	}
}
